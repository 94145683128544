<template>
  <div class="page-box box">
    <!-- 信息 -->
    <div class="top-box box">
      <div class="img-box">
        <van-image :src="courseDetail.imgUrls && courseDetail.imgUrls[0]"></van-image>
      </div>
      <div class="text-a ellipsis2">
        {{ courseDetail.courseName || '--' }}
      </div>
      <div class="price-box">
        <span>￥</span>
        {{ (parseFloat(courseDetail.fee || 0)).toFixed(2) }}
      </div>
    </div>

    <van-field
      v-model="payCheckInfo.mobileNo"
      class="mobile-box"
      type="number"
      label="手机号码"
      placeholder="填写手机号码"
      input-align="right"
    />

    <!-- 付款方式 -->
    <div class="pay-type box">
      <div class="title">
        付款方式
      </div>
      <div class="pay-a pay-box">
        <div class="label">
          <img
            class="img-bg"
            src="@assets/img/wechat-logo.png"
            alt=""
          >
          微信支付
        </div>
        <div class="right">
          <van-radio-group v-model="radio">
            <van-radio name="1"></van-radio>
          </van-radio-group>
        </div>
      </div>
      <div
        v-if="payCheckInfo.bindStatus"
        class="pay-b pay-box"
      >
        <div class="label">
          <img
            class="img-bg"
            src="@assets/img/bank-logo.png"
            alt=""
          >
          银行卡
        </div>
        <div class="right">
          <van-radio-group v-model="radio">
            <van-radio name="2"></van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>

    <!-- 支付 -->
    <div class="pay-btn-box">
      <div class="text">
        <span>￥</span>
        {{ (parseFloat(courseDetail.fee || 0)).toFixed(2) }}
      </div>
      <div
        class="pay-btn"
        @click="payBtn"
      >
        立即支付
      </div>
    </div>
  </div>
</template>

<script>
import { wxPay } from '@/utils/common.js'
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      mobile: '',
      radio: '1',
      payCheckInfo: {
        mobileNo: ''
      },
      courseDetail: {}
    }
  },
  computed: {
  },
  created () {
    this.payCheckInfo = JSON.parse(window.localStorage.getItem('payCheckInfo') || '{}')
    this.courseDetail = JSON.parse(window.localStorage.getItem('courseDetail') || '{}')
    // courseDetail
  },
  methods: {
    wxPay (data) {
      let vm = this;
      function onBridgeReady(data) {
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
          appId: data.appId || 'wx6890f57608f2cb62',  //公众号ID，由商户传入    
          timeStamp: data.timeStamp,   //时间戳，自1970年以来的秒数    
          nonceStr: data.nonceStr,      //随机串    
          package: data.pkg,
          signType: data.signType,     //微信签名方式：    
          paySign: data.paySign //微信签名
        },
        function(res) {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            console.log(res, '支付成功')
            // vm.goHomePage()
            vm.$toast.success('支付成功')
            window.localStorage.removeItem('payCheckInfo')
            window.localStorage.removeItem('courseDetail')
            setTimeout(() => {
              vm.$router.back()
            }, 1500)
          }
        });
      }
      if (typeof WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady(data));
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(data));
        }
      } else {
        onBridgeReady(data);
      }	
    },
    goHomePage () {
      this.$toast.success('支付成功, 返回首页')
      window.localStorage.removeItem('payCheckInfo')
      window.localStorage.removeItem('courseDetail')
      setTimeout(() => {
        // let backlen = history.length-2;
        // history.go(-backlen);
        this.$router.back()
      }, 1500)
    },
    async payBtn () {
      let data = {
        courseNo: this.$route.query.id || '',
        mobile: this.payCheckInfo.mobileNo,
        payWay: this.radio,
        totalAmount: this.courseDetail.fee
      }
      if (!data.mobile || data.mobile.length !== 11) {
        this.$toast('请填写正确手机号')
        return
      }
      console.log(data, 'data')
      this.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      let res = await this.$http.orderApi.orderAdd(data)
      if (res.code === 200) {
        this.$toast.clear()
        if (data.payWay == '2') {
          this.goHomePage()
        } else {
          this.wxPay(res.msg)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  height: 100vh;
  background: #f5f5f5;
  .top-box {
    background: #fff;
    margin-top: 10px;
    padding: 20px 15px 20px 174px;
    min-height: 117px;
    .img-box {
      width: 144px;
      height: 77px;
      position: absolute;
      left: 15px;
      top: 20px;
      img, .van-image {
        width: 100%;
        height: 100%;
      }
    }
    .text-a {
      line-height: 24px;
      font-size: 14px;
    }
    .price-box {
      margin-top: 16px;
      color: #D5423E;
      font-size: 12px;
    }
  }
  .mobile-box {
    background: #fff;
    margin-top: 10px;
  }

  .pay-type {
    background: #fff;
    margin-top: 10px;
    padding: 15px;
    .title {
      padding-bottom: 15px;
      font-size:15px;
      line-height: 16px;
    }
    .pay-box {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      .label {
        padding-left: 30px;
        float: left;
        position: relative;
        .img-bg {
          width: 18px;
          height: 18px;
          position: absolute;
          left: 0;
          top: 16px;
        }
      }
      .right {
        padding-top: 14px;
        float: right;
      }
    }
    // .pay-a {
      // background: url(@assets/img/wechat-logo.png) center left no-repeat;
      // background-size: 18px 18px;
    // }
    // .pay-b {
    //   background: url(@assets/img/bank-logo.png) center left no-repeat;
    //   background-size: 18px 18px;
    // }
  }
  .pay-btn-box {
    width: 100%;
    height: 55px;
    line-height: 55px;
    padding: 0 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    .text {
      color: #D5423E;
      font-size: 22px;
      font-weight: bold;
      span {
        font-size: 12px;
        line-height: 55px;
      }
    }
    .pay-btn {
      width: 230px;
      height: 40px;
      line-height: 40px;
      background: #282734;
      color: #fff;
      font-size: 14px;
      text-align: center;
      position: absolute;
      right: 20px;
      top: 7.5px;
      border-radius: 20px;
    }
  }
}
</style>
